<template>
  <div>
    <div class="content_box beforetab" id="working-progress-box">
      <form id="searchForm" @submit.prevent>
        <table class="tbl_search">
          <colgroup>
            <col width="60px">
            <col width="180px">
            <col width="150px">
            <col width="260px">
            <col width="*">
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX010T010.001') }}<!-- 조회 --></th>
              <td>
                <input v-model="searchParams.workType" type="radio" id="workType_E" name="workType" value="E" @change="changeCalcEiCatCd()">
                <label for="workType_E" class="mr10"><span></span>{{ $t('msg.ONEX010T010.002') }}<!-- 수출 --></label>
                <input v-model="searchParams.workType" type="radio" id="workType_I" name="workType" value="I" @change="changeCalcEiCatCd()">
                <label for="workType_I"><span></span>{{ $t('msg.ONEX010T010.003') }}<!-- 수입 --></label>
              </td>
              <td class="pr5">
                <select v-model="searchParams.kind" id="kind" @change="checkSearchValidation('kind')">
                  <option value="">{{ $t('msg.ONEX010T010.004') }}<!-- 선택 --></option>
                  <option v-for="(cvo, idx) in searchKindList" :key="'kind' + idx" :value="cvo.cd">{{ cvo.cdNm }}</option>
                  <option v-if="memberDetail.userCtrCd === 'CN'" :key="'kind6'" :value="'06'">FW S/R No</option>
                </select>
              </td>
              <td class="text_left pl0">
                <div v-if="searchParams.kind == '02'">
                  <input type="text" id="keyword"
                         v-model="searchParams.keyword"
                         :placeholder="searchParams.workType === 'E' ? placeHolder : $t('msg.ONEX010T010.075')"
                         @keyup="checkSearchValidation('keyword')"
                         @keypress.enter="search()"
                         @input="searchParams.keyword=searchParams.keyword.toUpperCase()"
                  >
                </div>
                <div v-else-if="searchParams.kind == '06'">
                  <input type="text" id="keyword"
                         v-model="searchParams.keyword"
                         :placeholder="'Enter FW S/R No'"
                         @keyup="checkSearchValidation('keyword')"
                         @keypress.enter="search()"
                         @input="searchParams.keyword=searchParams.keyword.toUpperCase()"
                  >
                </div>
                <div v-else-if="searchParams.kind == '04'">
                  <e-date-picker v-model="searchParams.fromDt" />
                </div>
                <div v-else class="form_wrap t2">
                  <e-date-range-picker-simple
                    id="fromDtToDt"
                    :sdate="searchParams.fromDt"
                    :edate="searchParams.toDt"
                    @change="changeDateRange"
                  />
                </div>
              </td>
              <td>
                <span>
                  <input type="radio" id="profileYn_Y" name="profileYn" v-model="searchParams.profileYn" value="Y">
                  <label for="profileYn_Y" class="mr10"><span></span>{{ $t('msg.ONEX010T010.006') }}<!-- My Shipments --></label>
                  <span class="position_relative mr20">
                    <button type="button" class="tbl_icon help" @click="showHelp01 = !showHelp01">help</button>
                    <div v-show="showHelp01" class="tooltip_wrap short position_absolute" style="width:180px; left:0; top:24px;">
                      <div class="cont">
                        <button type="button" class="close" @click="showHelp01 = false">close</button>
                        <ul class="bul_list_sm t2">
                          <li v-html="$t('msg.ONEX010T010.007')"><!-- Profile에 해당하는<br />리스트를 볼 수 있습니다 --></li>
                        </ul>
                      </div>
                    </div>
                  </span>
                  <input type="radio" id="profileYn_N" name="profileYn" v-model="searchParams.profileYn" value="N">
                  <label for="profileYn_N" class="mr10"><span></span>{{ $t('msg.ONEX010T010.008') }}<!-- All Shipments --></label>
                  <span class="position_relative">
                    <button type="button" class="tbl_icon help" @click="showHelp02 = !showHelp02">help</button>
                    <div v-show="showHelp02" class="tooltip_wrap short position_absolute" style="width:200px; left:0; top:24px;">
                      <div class="cont">
                        <button type="button" class="close" @click="showHelp02 = false">close</button>
                        <ul class="bul_list_sm t2">
                          <li v-html="$t('msg.ONEX010T010.009')"><!-- Profile의 소속 기업에 해당하는 <br> 리스트를 볼 수 있습니다. --></li>
                        </ul>
                      </div>
                    </div>
                  </span>
                </span>
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="search()">{{ $t('msg.ONEX010T010.010') }}<!-- 검색 --></a>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>

    <working-progress-export ref="wpExport" v-if="searchParams.workType === 'E'" :parent-info="wpExportInitParams" />
    <working-progress-import ref="wpImport" v-if="searchParams.workType === 'I'" :parent-info="wpImportInitParams" />
  </div>
</template>

<script>

import { rootComputed } from '@/store/helpers'
import store from '@/store/index'
export default {
  name: 'WorkingProgress',
  components: {
    EDatePicker: () => import('@/components/common/EDatePicker'),
    WorkingProgressExport: () => import('@/pages/trans/WorkingProgressExport'),
    WorkingProgressImport: () => import('@/pages/trans/WorkingProgressImport'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple')
  },
  data () {
    return {
      showHelp01: false,
      showHelp02: false,
      searchKindList: [
        { cd: '03', cdNm: this.$t('msg.MAIN100.009') }, // 출항일
        { cd: '01', cdNm: 'Booking Date' },
        { cd: '02', cdNm: 'Booking & B/L No' },
        { cd: '04', cdNm: this.$t('msg.ONEX010T010.141') } // 서류 마감일
        // { cd: '05', cdNm: this.$t('msg.ONEX010T010.142') } // 입항일
      ],
      wpExportInitParams: {},
      wpImportInitParams: {},
      searchParams: {
        workType: 'E', // 업무구분 (수출: E, 수입: I)
        kind: '01', // 검색구분 (Booking Date: 01, bkgNo/BlNo: 02, OnBoardDate: 03)
        fromDt: '',
        toDt: '',
        keyword: '',
        profileYn: 'Y'
      },
      // export
      searchKindListEx: [
        { cd: '03', cdNm: this.$t('msg.MAIN100.009') }, // 출항일
        { cd: '01', cdNm: 'Booking Date' },
        { cd: '02', cdNm: 'Booking & B/L No' },
        { cd: '04', cdNm: this.$t('msg.ONEX010T010.141') } // 서류 마감일
        // { cd: '05', cdNm: this.$t('msg.ONEX010T010.142') } // 입항일
      ],
      // import
      searchKindListIm: [
        { cd: '01', cdNm: this.$t('msg.ONEX010T010.142') }, // 입항일
        { cd: '02', cdNm: 'B/L No' }
      ],
      placeHolder: this.$t('msg.ONEX010T010.005'),
      afterClickImport: 'N'
    }
  },
  computed: {
    ...rootComputed,
    selectedProfile () {
      return this.$store.getters['auth/selectedProfile']
    },
    calcEiCatCd () {
      if (this.$store.getters['auth/getCalcEiCatCd'] === 'O') {
        return 'E'
      } else {
        return this.$store.getters['auth/getCalcEiCatCd']
      }
    }
  },
  watch: {
    'searchParams.workType': function (val) {
      if (val === 'E') {
        this.searchKindList = this.searchKindListEx

        this.placeHolder = this.$t('msg.ONEX010T010.005')
        if (this.searchParams.polBlNoYn !== 'Y' && this.searchParams.polBlNoYn !== undefined) {
          const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: 'W', endGap: 1 })
          this.searchParams.kind = '01'
          this.searchParams.fromDt = arrMonthInfo.stDt
          this.searchParams.toDt = arrMonthInfo.enDt
          this.searchParams.keyword = ''
        }
      } else {
        this.searchKindList = this.searchKindListIm
        // this.placeHolder = this.$t('msg.ONEX010T010.075') + `${this.serviceLang === 'KOR' ? '(\'KMTC\' 제외)' : ''}`
        this.searchParams.profileYn = 'N'
        this.searchParams.kind = '02'
        this.searchParams.keyword = ''
      }
    },
    '$store.state.trans.searchParams' () {
      console.log('searchParams')
      this.setVuexSearchParams()
    },
    calcEiCatCd () {
      this.searchParams.workType = this.calcEiCatCd
    }
  },
  created () {
    this._ = require('lodash')

    // 프로필에서 설정한 수출/수입 값으로 세팅
    this.initEiCatCd()
  },
  mounted () {
    this.setSearchParams()
  },
  methods: {
    initEiCatCd () {
      if (this.calcEiCatCd !== '') {
        this.searchParams.workType = this.calcEiCatCd
      } else {
        // 프로필에서 설정한 수출/수입 값으로 세팅
        if (this.selectedProfile.eiCatCd === 'A' || this.selectedProfile.eiCatCd === 'O') {
          // 프로필 값이 수출/수입이면 '수출'
          this.searchParams.workType = 'E'
        } else {
          this.searchParams.workType = this.selectedProfile.eiCatCd
        }
      }
    },
    setSearchParams () {
      if (Object.keys(this.$route.params).length > 0 || Object.keys(this.$route.query).length > 0) {
        // Query Parameter 가 있는경우
        // 기본값 셋팅 - 필요한 값이 다 넘오 오지 않을 경우 대비
        const params = Object.keys(this.$route.params).length > 0 ? this.$route.params : this.$route.query

        const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: '', startGap: 0, endCode: 'W', endGap: 1 })
        const def = {
          profileYn: 'N',
          kind: '01',
          fromDt: arrMonthInfo.stDt,
          toDt: arrMonthInfo.enDt
        }
        //~ 기본값 셋팅
        this.searchParams = this._.cloneDeep({ ...def, ...params })
        this.searchAct()
        //~ Query Parameter 가 있는경우
      } else {
        const tempPrevData = sessionStorage.getItem('working-progress-prev-data')
        const prevData = tempPrevData === null || tempPrevData === undefined ? undefined : JSON.parse(tempPrevData)

        if (prevData !== undefined) {
          // 상세 페이지에서 돌아 왔을때 검색 조건 유지
          this.searchParams = this._.cloneDeep(prevData.searchParams)
          // JamSin his 진행중 업무 이전 데이타가 저장된 상태면 자동조회
          this.searchAct()
          //~ 상세 페이지에서 돌아 왔을때 검색 조건 유지
        } else {
          // 기본 검색 조건 셋팅
          const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: '', endGap: 0 })
          this.searchParams.fromDt = arrMonthInfo.stDt
          this.searchParams.toDt = arrMonthInfo.enDt
          //JamSin 속도 문제로 막음
          //this.searchAct()
          //~ 기본 검색 조건 셋팅
        }
      }
    },
    setVuexSearchParams () {
      const arrMonthInfo = this.$ekmtcCommon.getDefaultStEnDt({ startCode: 'W', startGap: 1, endCode: '', endGap: 0 })
      const def = {
        profileYn: 'N',
        kind: '01',
        fromDt: arrMonthInfo.stDt,
        toDt: arrMonthInfo.enDt
      }

      this.searchParams = this._.cloneDeep({ ...def, ...this.$store.state.trans.searchParams })
      this.searchAct()
    },
    search () {
      if (!this.checkSearchValidationAll()) {
        return
      }
      // 해당 페이지에서 검색 버튼 클릭시 Query Parameter 초기화
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }

      // ------- 3팀 w.m.h 2022/03/15 추가--------
      this.searchParams.polBlNoYn = 'N'

      if (this.searchParams.kind !== '02' && this.searchParams.kind !== '06') {
          this.searchParams.keyword = ''
      }
      // -----------------------------------------
      this.searchAct()
    },
    searchAct () {
      if (this.searchParams.workType === 'E') {
        // -----------------  3팀 w.m.h 2022/03/15 추가----------
        if (this.searchParams.polBlNoYn === 'Y') {
          this.searchParams.keyword = this.searchParams.polBlNo
        }
        // -----------------------------------------------------

        if (this.$refs.wpExport === undefined) {
          this.wpExportInitParams = this._.cloneDeep(this.searchParams)
        } else {
          this.$refs.wpExport.search(this.searchParams)
          console.log('searchAct >>> ', this.searchParams)
        }
        store.commit('updateWorkingEiCatCd', 'O')
      } else if (this.searchParams.workType === 'I') {
        // -----------------  3팀 w.m.h 2022/03/22 추가----------
        if (this.searchParams.polBlNoYn === 'Y') {
          this.searchParams.keyword = this.searchParams.polBlNo
        }
        // -----------------------------------------------------

        if (this.$refs.wpImport === undefined) {
          this.wpImportInitParams = this._.cloneDeep(this.searchParams)
        } else {
          this.$refs.wpImport.search(this.searchParams)
        }
        store.commit('updateWorkingEiCatCd', 'I')
      }
    },
    changeDateRange (s, e) {
      this.searchParams.fromDt = s
      this.searchParams.toDt = e

      this.checkSearchValidation('fromDtToDt')
    },
    checkSearchValidationAll () {
      let isOk = true
      const prefix = ['kind', 'fromDtToDt', 'keyword']

      prefix.forEach(el => {
        if (!this.checkSearchValidation(el)) {
          isOk = false
        }
      })

      return isOk
    },
    checkSearchValidation (id) {
      const searchParams = this.searchParams
      const frm = document.querySelector('#searchForm')
      const selector = frm.querySelector('#' + id)
      let isOk = true

      if (selector === null || selector === undefined) {
        return true
      }

      this.$ekmtcCommon.hideErrorTooltip(selector)

      switch (id) {
        case 'kind':
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
          break
        case 'fromDtToDt':
          if (searchParams.kind !== '02' && (this.$ekmtcCommon.isEmpty(searchParams.fromDt) || this.$ekmtcCommon.isEmpty(searchParams.toDt))) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else if (searchParams.kind !== '02' && parseInt(searchParams.fromDt, 10) > parseInt(searchParams.toDt, 10)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.ONEX010T010.128'))
            isOk = false
          }
          break
        case 'keyword':
          if (searchParams.kind === '02' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
          break
      }
      return isOk
    },
    changeCalcEiCatCd () {
      let workingEiCatCd = ''

      if (this.searchParams.workType === 'E') {
        workingEiCatCd = 'O'
      } else {
        workingEiCatCd = 'I'
      }

      this.$store.commit('auth/updateCalcEiCatCd', workingEiCatCd)
    }
  }
}

</script>
